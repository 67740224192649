.navbar__container{
    background-color: #ffffff;
    box-shadow: var(--navbar-box-shadow);
    height: var(--navbar-height);
    line-height: var(--navbar-height);
    padding: 6px 50px 0 50px;
    /* position: fixed; */
    width: 100%;
    z-index: var(--zindex-navbar);
}

.navbar__container .ant-menu-horizontal{
    border: unset;
}

.navbar{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
}

.navbar__logo a{
    display: flex;
    justify-content: center;
    align-content: center;
}

.navbar__logo a img{
    width: 100px;
    height: auto;
}

.navbar__logo img{
    width: 100%;
    height: auto;
}

.navbar__menu, .navbar__menu--account{
    margin-top: 12px;
}

.navbar__menu{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex: 1; 
}

.navbar__menu--links, 
.navbar__menu--account{
    font-weight: 600;
}

.navbar__menu--links{
    flex: 1;
}

.navbar__menu--account{
    display: flex;
    flex-direction: row;
}

.navbar__menu--account .ant-menu-submenu {
    /* margin-left: auto; */
    color: var(--primary-color);
}

.navbar__menu--account .account-item{
    margin: 0;
}

.navbar__btn--advanced-search{
    align-self: center;
}

@media screen and (max-width:1200px) {

    .navbar__container{
        height: auto;
        padding: 10px 30px;
    }

    .navbar__logo a{
        flex: 1;
    }

    .navbar__menu--collapse{
        border: none;
    }
    
    .navbar__menu{
        order: 1;
        justify-content: space-between;
    }

    
    
    .navbar__menu--links{
        flex-direction: column;
        width: auto;
        flex: 0;
        padding: 0;
    }

    .navbar__menu--account .ant-space-item p{
        display: none;
    }

    .header__logo{
        order: 2;
    }

    .navbar__menu--account{
        margin-left: auto;
        order: 3;
    }

    /* Remove preset border on nav items */
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
        border: none;
    }

}

@media screen and (max-width:600px){

    .navbar{
        /* display: grid; */
        grid-template-columns: 1fr;
    }
    .navbar__logo{
        display: none;
    }
}

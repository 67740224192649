#print * {
    font-size: 13px;
    font-family: 'Times New Roman';
    width: 350px;
}

#print td,
#print th,
#print tr,
#print table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

#print td.description,
#print th.description {
    width: 75px;
    max-width: 75px;
}

#print td.quantity,
#print th.quantity {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

#print td.price,
#print th.price {
    width: 60px;
    max-width: 60px;
    word-break: break-all;
}

#print .centered {
    text-align: center;
    align-content: center;
}

#print .ticket {
    width: 200px;
    max-width: 200px;
}

#print .bold {
    font-weight: bold;
}

#print  img {
    /* max-width: inherit; */
    /* width: inherit; */
    max-width: 200px;
}

@media print {
    /* .hidden-print,
    .hidden-print * {
        display: none !important;
    } */
}

#print  p {
    margin: 2px;
    padding: 0px;
}

@media print {
    body * {
      visibility: hidden;
    }
  
    #print * {
      visibility: visible;
    }

    .print-btn {
        display: none;
    }
  
    #section-to-print {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
.scheduler .ant-steps .ant-steps-item {
    flex: 1 1 0px;
}

.scheduler .ant-steps-item-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1vw;
    min-width: 200px;
}

.scheduler .ant-select-selection-item{
    padding: 0;
}

.scheduler .ant-select-selection-placeholder, 
.scheduler .ant-steps-label-vertical .ant-steps-item-content{
    text-align: left;
}

.scheduler .ant-steps{
    overflow-x: auto;
    margin: 10px;
    padding-top: 10px;
}
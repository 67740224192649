.modal__container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: var(--zindex-modal);
    top: 0;
    left: 0;
    overflow-y: auto;
}

.modal__overlay{
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
}

.modal__overlay:before{
    content: '';
}

.modal{
    position: relative;
    margin: 50px auto;
    background-color: white;
    padding: 30px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.modal__icon--close{
    position: absolute;
    right: 30px;
    color: black;
}

.ant-table:hover .modal .modal__icon--close, 
.ant-table:hover .modal p{
    color: black;
}

.modal__header{
    padding: 10px 0;
    display: flex;
    flex-direction: column;
}

.modal__header .modal__icon .anticon{
    font-size: 48px;
    margin-bottom: 10px;
}

.modal:last-child{
    padding-bottom: 20px;
}

.modal:last-child .ant-form .ant-row .ant-form-item:last-child{
    margin-bottom: 0;
}

.modal__action-group{
    margin-top: 20px;
}
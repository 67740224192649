.logo-container {
    display: flex!important;
    justify-content: center!important;
    margin: 25px 0;
}

.logo {
    max-width: 200px;
}

.form-container {
    width: 300px;
}

.login-container {
    display: flex;
    justify-content: center;
}

.form-container .ant-btn-round {
    height: 45px;
    padding: 4px 16px;
    border-radius: 20px;
}
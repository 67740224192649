:root {

  /* --- Colors --- */
  --primary-color: #41740e;
  --primary-7: #222270;
  --success-color: #28a745;
  --error-color: rgb(245, 34, 45);  
  --warning-color: #faad14;
  --screen-bg: #F0F0F0;
  --gray-1: #ffffff;
  --gray-2: ##fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #595959;
  --gray-9: #434343;
  --gray-10: #262626;
  --gray-11: #1f1f1f;

  /* --- Text --- */
  --text-secondary: rgba(0,0,0,0.5);
  --dark-theme-text-secondary: rgba(255,255,255,0.5);

  /* --- Shadows --- */
  --box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  --navbar-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --message-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --panel-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  /* --- Position --- */
  --zindex-sticky: 999;
  --zindex-navbar: 1010;
  --z-index-station-sidebar:  1009;
  --zindex-modal: 1010;
  --navbar-height: 64px;

  --tooltip-bg: var(--gray-10);

  /* --- Status --- */
  --success-status: radial-gradient(circle, rgba(57,255,20,1) 0%, rgba(134,167,40,1) 80%);
  --error-status: radial-gradient(circle, var(--error-color) 0%, #960d0d 50%);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'PoppinsRegular';
  font-weight: normal;
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

html{
  height: fill-available;
  height: -webkit-fill-available;
  min-height: 100vh;
}

p{
  margin: 0;
}

.text-secondary, 
.text-secondary.ant-typography{
  color: var(--text-secondary);
  font-weight: 700;
}

.layout{
  padding-top: var(--navbar-height);
  background-color: var(--screen-bg);
}

.container{
  width: 100%;
  margin: 0 auto;
}

::-webkit-scrollbar{
	width: 15px;
  height: 15px;
	background-color: unset;
}

::-webkit-scrollbar-track{
	border-radius: 5px;
	background-color: var(--gray-5);
  margin: 10px 0;
}

::-webkit-scrollbar-thumb{
	border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0,0,0, 0.2);
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0, 0.2);
	background-color: var(--gray-6);
}

/* ---- Buttons ---- */

.btn__secondary--dark-gray{
  background-color: var(--gray-10);
  border: 1px solid var(--gray-10);
  color: white;
  transition: padding 12s smooth;
}

.btn__secondary--dark-gray:hover,
.btn__secondary--dark-gray:focus,
.btn__secondary--dark-gray:active{
  background-color: var(--gray-9);
  border: 1px solid var(--gray-10);
  color: white;
}

.btn__secondary--success{
  background-color: var(--success-color);
  border: var(--success-color);
  color: white;
  transition: padding 12s smooth;
}

.btn__secondary--success:hover,
.btn__secondary--success:focus,
.btn__secondary--success:active{
  background-color: rgba(109, 236, 93, 0.536);
  border: var(--success-color);
  color: white;
}

.btn--hover span:not(.anticon){
  display: none;

}
.btn--hover:hover span:not(.anticon){
  display: inline-block;
}

/* ---- Forms ---- */

.form__layout--vertical-inline{
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  overflow-x: auto;
}

.ant-form-vertical > .label-center > .ant-form-item-label{
  text-align: center;
}

.radio__label{
  font-weight: 600;
}

/* Chrome, Safari, Edge, Opera */
input.no-spinner::-webkit-outer-spin-button,
input.no-spinner::-webkit-inner-spin-button,
.no-spinner > input::-webkit-outer-spin-button,
.no-spinner > input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input.no-spinner[type=number], 
.no-spinner > input[type=number] {
  -moz-appearance: textfield !important;
}

/* --------- */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* --------- Ant Design --------- */

.ant-layout {
  background-color: var(--screen-bg);
}

.ant-message-notice-content{
  box-shadow: var(--message-box-shadow);
}

.ant-divider-horizontal.ant-divider-with-text::after{
    border-top: 1px solid rgba(0,0,0,0.15);
}

.ant-divider-horizontal.ant-divider-with-text{
  font-weight: 600;
}

.ant-table-row:hover .ant-btn-background-ghost{
  background-color: white;
}

.ant-table-row:hover .ant-btn-link{
  transition: unset;
  color: white;
}

.ant-table-row:hover .ant-btn-link:hover{
  color: white;
}

.ant-typography.editable__field{
  background-color: var(--gray-3);
  padding: 3px 8px;
  border: 1px solid var(--gray-6);
  margin: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ant-typography-edit-content.editable__field{
  height: inherit;
  padding: 0;
  border: 0;
}

.ant-btn.ant-btn-primary.ant-btn-background-ghost {
  font-weight: 700;
}

.ant-form-item-explain-error{
  margin-bottom: 15px;
}

.ant-table-tbody > tr.ant-table-row:hover > td > .ant-btn-link,
.ant-table-tbody > tr > td.ant-table-cell-row-hover > .ant-btn-link{
  color: #ffffff;
}

.ant-table-tbody > tr.ant-table-row:hover > td > .ant-btn-link span:nth-child(2):hover,
.ant-table-tbody > tr > td.ant-table-cell-row-hover > .ant-btn-link span:nth-child(2):hover{
  border-bottom: 1px solid white;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td{
  padding: 8px 15px;
}

.ant-pagination-total-text{
  height: auto;
  word-break: break-all;
}

/* --- Media Queries --- */

@media screen and (max-width: 576px){
  .container{
    max-width: 100%;
  }
}

@media screen and (min-width: 576px){

  .container{
    max-width: 540px;
  }
}


@media screen and (min-width: 768px){

  .container{
    max-width: 720px;
  }
}


@media screen and (min-width:992px){

  .container{
    max-width: 960px;
  }
}

@media screen and (min-width:1200px){
  
  .container{
    max-width: 1140px;
  }
}

@media screen and (min-width:1400px){
  
  .container{
    max-width: 1320px;
  }
}

@media screen and (min-width:2560px)  {
 
  .container{
    max-width: 1800px;
  }
}

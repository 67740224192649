.form-container {
    background-color: white;
    width: 350px;
}

.form-container > h5{
    margin-bottom: 20px;
}

.form-container#sign-up{
    width: 500px;
}

.verify-code-page Input{
    text-align: center;
}